.blur {
  
    animation: blur 3s ease-in-out infinite;
  }
  
  @keyframes blur {
    0% {
      filter: blur(0px);
    }
    50% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }

  .focus {
   
    filter: blur(10px);
    animation: focusEffect 3s ease-in-out infinite;
  }
  
  @keyframes focusEffect {
    0% {
      filter: blur(10px);
    }
    50% {
      filter: blur(0px);
    }
    100% {
        filter: blur(10px);
      }
  }

  @keyframes colorFlashBlack {
    0% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(0);
    }
    100% {
      filter: brightness(1);
    }
  }
  
  .colorFlashBlack {
    animation: colorFlashBlack 3s infinite;
  }

  @keyframes colorFlashBlack2 {
    0% {
      filter: brightness(1);
    }
    10% {
      filter: brightness(0.5);
    }
    20% {
      filter: brightness(0.8);
    }
    30% {
      filter: brightness(0.3);
    }
    40% {
      filter: brightness(0.7);
    }
    50% {
      filter: brightness(0.4);
    }
    60% {
      filter: brightness(1);
    }
    70% {
      filter: brightness(0.6);
    }
    80% {
      filter: brightness(0.9);
    }
    90% {
      filter: brightness(0.2);
    }
    100% {
      filter: brightness(1);
    }
  }
  
  .colorFlashBlack2 {
    animation: colorFlashBlack2 3s infinite;
  }

  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    10% {
      filter: hue-rotate(30deg);
    }
    20% {
      filter: hue-rotate(60deg);
    }
    30% {
      filter: hue-rotate(90deg);
    }
    40% {
      filter: hue-rotate(120deg);
    }
    50% {
      filter: hue-rotate(150deg);
    }
    60% {
      filter: hue-rotate(180deg);
    }
    70% {
      filter: hue-rotate(210deg);
    }
    80% {
      filter: hue-rotate(240deg);
    }
    90% {
      filter: hue-rotate(270deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }
  
  .hue {
    animation: hue 3s infinite;
  }

  @keyframes grayscaleFlash{
    0% {
      filter: grayscale(0%);
    }
    20% {
      filter: grayscale(50%);
    }
    40% {
      filter: grayscale(100%);
    }
    60% {
      filter: grayscale(50%);
    }
    80% {
      filter: grayscale(75%);
    }
    100% {
      filter: grayscale(0%);
    }
  }
  
  .grayscale {
    animation: grayscaleFlash 3s infinite;
  }
  
  
















































































































































































































